import React, { MouseEventHandler } from 'react'
import classNames from 'classnames'

import { IPromptFilter } from '../../Utils/interface'

type subFiltersProps = {
  currentGroupFacetName: string
  filtersCount: number
  filtersDropdownOpen: boolean
  tempFilters: IPromptFilter | null
  callBack: {
    dropdown: MouseEventHandler<HTMLButtonElement>
    select: Function
    clear: MouseEventHandler<HTMLButtonElement>
    apply: MouseEventHandler<HTMLButtonElement>
  }
}

const SubFilters = (props: subFiltersProps) => {
  const {
    currentGroupFacetName,
    filtersCount,
    filtersDropdownOpen,
    tempFilters,
    callBack,
  } = props

  return (
    <div className='searchPrompt__filter-dropdown-wrapper'>
      <button
        tabIndex={0}
        className={classNames(`searchPrompt__filter-dropdown-button`, {
          'searchPrompt__filter-dropdown-button--open': filtersDropdownOpen,
        })}
        onClick={callBack.dropdown}>
        <span className='searchPrompt__filter-dropdown-button-content'>
          {currentGroupFacetName}
          {filtersCount ? (
            <span className='searchPrompt__filters-count'>{filtersCount}</span>
          ) : null}
        </span>
        <i
          className='icon__lg searchPrompt__filter-dropdown-icon'
          aria-hidden='true'>
          keyboard_arrow_down
        </i>
      </button>
      <div
        aria-hidden={!filtersDropdownOpen}
        className={classNames(`searchPrompt__filter-dropdown`, {
          'searchPrompt__filter-dropdown--show': filtersDropdownOpen,
        })}>
        <ul className='searchPrompt__filter-dropdown-list'>
          {tempFilters?.options.map((option) => (
            <li
              key={option.value}
              className='searchPrompt__filter-dropdown-list-item'>
              <button
                className='group searchPrompt__filter-select-button'
                onClick={() => callBack.select(option)}
                aria-checked={option.selected}>
                <span className='searchPrompt__filter-label'>
                  {option.name}
                </span>
                <span
                  className={classNames(`searchPrompt__filter-selected`, {
                    'searchPrompt__filter-selected--active': option.selected,
                  })}>
                  <i className='icon__md' aria-hidden='true'>
                    check
                  </i>
                </span>
              </button>
            </li>
          ))}
        </ul>
        <div className='searchPrompt__button-wrapper'>
          <button
            className='button link__color--primary button__type--secondary button__size--base searchPrompt__clear-filter-button'
            onClick={callBack.clear}>
            Clear All
          </button>
          <button
            className='button button__color--primary button__type--primary button__size--base searchPrompt__apply-filter-button'
            onClick={callBack.apply}>
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}

export default SubFilters
