import React, { useMemo, useState, useEffect } from 'react'

import { useDebounce, defaults, filtersJoint } from '../../Utils/utils'
import { IPromptFilter, ISubPromptFilter } from '../../Utils/interface'
import IProps from './interface'
import ContentTypeFilter from '../../Element/ContentTypeFilter/ContentTypeFilter'
import CurrentQuickLinks from './CurrentQuickLinks'
import AutoCompletes, {
  getAutoCompletes,
} from '../../Element/AutoCompletes/AutoCompletes'
import SubFilters from './SubFilters'
import { IAutoCompleteItem } from '../../Element/AutoCompletes/interface'

const SearchPrompt = (props: IProps) => {
  const {
    baseURL = '',
    resultsURL,
    introText,
    placeholders,
    promptContentTypes,
  } = props

  const [currentContentType, setContentType] = useState<string | null>(null)
  const [filtersDropdownOpen, setfiltersDropdown] = useState<boolean>(false)
  const [searchInput, setSearchInput] = useState<string>('')
  const [autoCompletes, setAutoCompletes] = useState<
    { items: IAutoCompleteItem[]; suggestions: string[] } | undefined
  >()
  const [filters, setFilters] = useState<IPromptFilter | null>(null)
  const [placeholder, setPlaceholder] = useState(placeholders.default)
  const [tempFilters, setTempFilters] = useState<IPromptFilter | null>(null)
  const debouncedSearchTerm = useDebounce(searchInput, 700)

  /**
   * This reads the current filters selected and returns an array of their values.
   * To be used in data sent to the back-end.
   * @returns string[]
   */
  const getSelectedFilters = () => {
    const appliedFilters: ISubPromptFilter[] | undefined =
      filters?.options.filter((option: ISubPromptFilter) => option.selected)
    const filtersList: string[] = []
    appliedFilters?.map((filter) => {
      filtersList.push(filter.value)
    })
    return filtersList
  }

  const toggleDropdown = () => {
    setfiltersDropdown(!filtersDropdownOpen)
  }

  const filtersCount = useMemo(() => {
    return filters?.options.filter((option) => option.selected).length
  }, [filters])

  const currentQuickLinks = useMemo(() => {
    return (
      promptContentTypes.find((item) => item.value === currentContentType)
        ?.quicklinks || null
    )
  }, [currentContentType])

  const clearInput = () => {
    setSearchInput('')
  }

  const currentGroupFacet = useMemo(() => {
    return promptContentTypes.find((item) => item.value === currentContentType)
      ?.filters
  }, [currentContentType])

  const clearFilters = () => {
    if (filters) {
      setFilters({
        ...filters,
        options: filters?.options.map((option) => ({
          ...option,
          selected: false,
        })),
      })

      setfiltersDropdown(false)
    }
  }

  const applyFilters = () => {
    setFilters(tempFilters)
    setfiltersDropdown(false)
  }

  const selectFilterOption = ({ value }) => {
    if (tempFilters) {
      const updatedFilters: IPromptFilter | null = { ...tempFilters }
      const updatedOptions = updatedFilters?.options.map((option) => {
        if (option.value === value) {
          return { ...option, selected: !option.selected }
        }
        return option
      })
      updatedFilters.options = updatedOptions
      setTempFilters(updatedFilters)
    }
  }

  const handleContentTypeChange = (value: string) => {
    setContentType(value)
    setfiltersDropdown(false)
  }

  const sendToResultsPage = () => {
    if (searchInput.length > 0) {
      const body = {
        term: searchInput,
        group: currentContentType || defaults.group,
        filters: getSelectedFilters().join(filtersJoint),
      }

      const bodyToParams = new URLSearchParams(body).toString()
      location.href = `${resultsURL}?${bodyToParams}`
    }
  }

  /**
   * This listens to key strokes in the search input, and if there is a search term, and the enter key is hit, it submits the search
   * @param key string
   */
  const supportEnterKey = (key: string) => {
    if (searchInput.length > 0 && key === 'Enter') {
      sendToResultsPage()
    }
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      // Query API and populate the auto completes array
      getAutoCompletes({
        baseURL,
        searchTerm: debouncedSearchTerm,
        contentType: currentContentType,
        callBack: setAutoCompletes,
      })
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    setFilters(
      promptContentTypes.find((item) => item.value === currentContentType)
        ?.filters || null
    )
    setPlaceholder(
      placeholders[(currentContentType || '').toLowerCase()] ||
        placeholders.default
    )
  }, [currentContentType])

  useEffect(() => {
    // Clear the auto-complete array
    setAutoCompletes(undefined)
  }, [searchInput, currentContentType])

  useEffect(() => {
    if (filtersDropdownOpen) setTempFilters(filters)
  }, [filtersDropdownOpen])

  return (
    <div className='searchPrompt'>
      <div className={`searchPrompt__inner`}>
        <div className='searchPrompt__heading-wrapper'>
          <div className='searchPrompt__heading'>{introText}</div>
          <ContentTypeFilter
            groupFacets={promptContentTypes}
            callBack={(value: string) => handleContentTypeChange(value)}
            allowUnselect
            isHeader
          />
        </div>
        <div className='searchPrompt__form-wrapper'>
          <div>
            <div className='searchPrompt__search-input-wrapper'>
              <label
                htmlFor='global-search'
                className='sr-only'
                id='global-search-label'>
                Search:
              </label>
              <input
                type='text'
                id='global-search'
                aria-controls='suggestions-list'
                aria-labelledby='global-search-label'
                className='searchPrompt__search-input'
                placeholder={placeholder}
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value)
                }}
                onKeyUp={(e) => supportEnterKey(e.key)}
                autoComplete='off'
              />
              {searchInput?.length ? (
                <button
                  className='button button__color--tertiary button__type--secondary button__size--base button__icon searchPrompt__input-clear-button'
                  aria-label='clear input'
                  onClick={clearInput}
                  tabIndex={0}>
                  <i className='searchPrompt__clear-icon' aria-hidden='true'>
                    close
                  </i>
                </button>
              ) : null}
              {currentGroupFacet && typeof filtersCount === 'number' && (
                <SubFilters
                  filtersCount={filtersCount}
                  currentGroupFacetName={currentGroupFacet.name}
                  filtersDropdownOpen={filtersDropdownOpen}
                  tempFilters={tempFilters}
                  callBack={{
                    dropdown: toggleDropdown,
                    select: selectFilterOption,
                    clear: clearFilters,
                    apply: applyFilters,
                  }}
                />
              )}
              <button
                className='button button__color--primary button__type--primary button__size--base button__icon searchPrompt__input-search-button'
                tabIndex={0}
                onClick={() => sendToResultsPage()}>
                <i className='icon__lg' aria-hidden='true'>
                  search
                </i>
              </button>
            </div>
            <button
              className='button button__color--primary button__type--primary button__size--base button__fullWidth searchPrompt__input-search-button-mobile'
              onClick={() => sendToResultsPage()}>
              <span className='searchPrompt__input-button-mobile-content'>
                Search
                <i className='icon__lg' aria-hidden='true'>
                  search
                </i>
              </span>
            </button>
          </div>
          {searchInput &&
          (autoCompletes?.items || autoCompletes?.suggestions) ? (
            <AutoCompletes
              items={autoCompletes.items}
              suggestions={autoCompletes.suggestions}
              searchTerm={searchInput}
              suggestionsCallBack={setSearchInput}
            />
          ) : null}
        </div>
        {currentQuickLinks?.links.length && (
          <CurrentQuickLinks
            links={currentQuickLinks.links}
            label={currentQuickLinks.label}
          />
        )}
      </div>
    </div>
  )
}

export default SearchPrompt
