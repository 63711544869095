import React from 'react'
import { TAutoComplete, TAutoCompletes, IAutoCompleteItem } from './interface'
import {
  defaults,
  dataFetcher,
  validateAutoCompleteData,
  END_POINTS,
} from '../../Utils/utils'
import { IAutoCompleteResponse } from '../../Utils/interface'

export const getAutoCompletes = async (props: {
  baseURL: string
  searchTerm: string
  contentType: string | null
  callBack: Function
}) => {
  const { baseURL = '', searchTerm, contentType, callBack } = props
  if (searchTerm.length >= defaults.minSearchString) {
    const body = {
      Term: searchTerm,
      Group: contentType || defaults.group,
      Filters: [],
    }
    const response: IAutoCompleteResponse = await dataFetcher(
      `${baseURL}${END_POINTS.autocomplete}`,
      body
    )
    if (response?.results) {
      const validData = validateAutoCompleteData(response)
      if (validData.log.length > 0) {
        console.log(validData.log)
      }
      const aggregatedAutocompleteItems: {
        items: IAutoCompleteItem[]
        suggestions: string[]
      } = { items: [], suggestions: [] }
      if (validData.results) {
        aggregatedAutocompleteItems.items?.push(...response.results)
      }
      if (validData.suggestions) {
        aggregatedAutocompleteItems.suggestions?.push(...response.suggestions)
      }
      if (
        aggregatedAutocompleteItems.items.length > 0 ||
        aggregatedAutocompleteItems.suggestions.length > 0
      ) {
        callBack(aggregatedAutocompleteItems)
      } else {
        callBack([])
      }
    }
  } else {
    callBack(undefined)
  }
}

/**
 * This breaks up the text to insert a <span /> surrounding the martched "search term"
 * @param props Object
 * @returns JSX
 */
const AutoComplete = (props: TAutoComplete) => {
  const { searchTerm, item } = props
  const regex = new RegExp(searchTerm, 'ig')
  const split = item.split(regex)
  const matches = item.match(regex) || []
  const result: React.JSX.Element[] = []
  for (let i = 0; i <= split.length; i += 1) {
    result.push(split[i] as unknown as React.JSX.Element)
    if (matches[i]) {
      result.push(
        <span key={i} className='autocompletes__match'>
          {matches[i]}
        </span>
      )
    }
  }
  return <span>{result}</span>
}

const AutoCompletes = (props: TAutoCompletes) => {
  const { searchTerm, items, suggestions, suggestionsCallBack } = props

  return (
    <div className='autocompletes__list-wrapper'>
      <ul
        id='suggestions-list'
        role='listbox'
        aria-labelledby='global-search'
        className='autocompletes__list'
        tabIndex={2}>
        {items.map((item, index) => {
          return (
            <li
              role='option'
              className='autocompletes__item'
              key={`${index}-${item.title}`}>
              <a href={item.url} className='autocompletes__link link'>
                <AutoComplete searchTerm={searchTerm} item={item.title} />
                {item.contentType && (
                  <span className='autocompletes__type'>
                    {item.contentType}
                  </span>
                )}
              </a>
            </li>
          )
        })}
        {suggestions.map((suggestion, index) => {
          return (
            <li
              role='option'
              className='autocompletes__item'
              key={`${index}-suggestion-${suggestion}`}>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  suggestionsCallBack(suggestion)
                }}
                className='autocompletes__link link'>
                <span>
                  <AutoComplete searchTerm={searchTerm} item={suggestion} />
                  ...
                </span>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default AutoCompletes
