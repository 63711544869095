import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import IProps from './interface'
import { defaults } from '../../Utils/utils'

const ContentTypeFilter = (props: IProps) => {
  const introText = 'Category Filters:'
  const {
    groupFacets,
    callBack = () => {},
    allowUnselect = false,
    isHeader = false,
    preSelected,
  } = props

  const [selectedValue, setSelectedValue] = useState(preSelected)

  /**
   * This toggles the contentType buttons (search prompt) or sends the selected value (search results)
   * @param value string
   */
  const handleSelect = (value: string) => {
    if (value === selectedValue && allowUnselect) {
      callBack(defaults.group)
      setSelectedValue(defaults.group)
    } else {
      setSelectedValue(value)
      callBack(value)
    }
  }

  /**
   * Updates the selected value on change
   */
  useEffect(() => {
    setSelectedValue(preSelected)
  }, [preSelected])

  return (
    <div
      className={classNames(`contentTypeFilter`, {
        'contentTypeFilter--header': isHeader,
      })}>
      <div
        role='group'
        aria-labelledby='categoryFiltersLabel'
        className='contentTypeFilter__group'>
        <span id='categoryFiltersLabel' className='sr-only'>
          {introText}
        </span>
        {groupFacets?.map((groupFacet) => {
          const { value, count = 0, name } = groupFacet
          return (
            <button
              key={groupFacet.value}
              className={classNames('contentTypeFilter__button', {
                'contentTypeFilter__button--selected': value === selectedValue,
              })}
              disabled={!isHeader && count === 0}
              role='radio'
              aria-checked={value === selectedValue}
              aria-label={count > 0 ? `${name} item count ${count}` : undefined}
              onClick={() => handleSelect(value)}>
              {name}{' '}
              {count > 0 && (
                <span className=''>({count.toLocaleString()})</span>
              )}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default ContentTypeFilter
