import React from 'react'
import ILink from '../../../Element/Link/interface'

type currentQuickLinksProps = {
  links: ILink[]
  label: string
}

const CurrentQuickLinks = (props: currentQuickLinksProps) => {
  const { links, label } = props

  return (
    <div className='searchPrompt__quick-links'>
      <h2 className='searchPrompt__quick-links-label'>{label}</h2>
      <ul className='searchPrompt__quick-links-list'>
        {links.map((link) => (
          <li key={link.text} className=''>
            <a
              href={link.href}
              target={link.target}
              className='searchPrompt__link'>
              <div className='searchPrompt__link-item'>
                <i className='searchPrompt__link-icon' aria-hidden='true'>
                  arrow_right_alt
                </i>
                <span className='searchPrompt__link-text'>{link.text}</span>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CurrentQuickLinks
