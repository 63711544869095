import { useEffect, useState } from 'react'
import { IAutoCompleteResponse, ISearchResponse } from './interface'

const serverURL = '/api/search'

export const defaults = {
  error: 'An error has occured, please reload the page or try again later.',
  minSearchString: 3,
  group: 'All',
  groupCourses: 'Courses',
  pageSize: 10,
  authorizedKeys: [
    'filters',
    'group',
    'page',
    'pageid',
    'pagesize',
    'sorttype',
    'sortvalue',
    'term',
  ],
  sortOptions: {
    name: '',
    label: 'Select sort option',
    prefix: 'Sort by:',
    optionsLabel: 'sort by options list',
  },
  clearFlag: {
    filter: 'All',
    status: false,
  },
}

export const END_POINTS = {
  search: `${serverURL}/contentsearch`,
  autocomplete: `${serverURL}/quicksearch`,
  courses: `${serverURL}/coursesearch`,
}

export const filtersJoint = '@@@@@@'

/**
 * This fetches data to an endpoint
 * @param endpoint string
 * @param body string - payload
 * @returns object
 */
export const dataFetcher = async (endpoint: string, body: {}) => {
  try {
    const response = fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    if (!(await response).ok) {
      throw new Error('Network response was not Ok')
    }
    const data = (await response).json()
    return data
  } catch (error) {
    // console.error('error!!!', error)
  }
}

export const useDebounce = (inputValue: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [inputValue, delay])

  return debouncedValue
}

const getType = (obj: any) => {
  const lowerCaseTheFirstLetter = (str: string) =>
    str[0].toLowerCase() + str.slice(1)
  const type = typeof obj
  if (type !== 'object') {
    return type
  }

  return lowerCaseTheFirstLetter(
    Object.prototype.toString.call(obj).replace(/^\[object (\S+)\]$/, '$1')
  )
}

export const validateData = (data: ISearchResponse) => {
  const defaultResponse = {
    categorisationFacets: true,
    categorisationGroupFacets: true,
    categorisationSortItems: true,
    pagination: true,
    results: true,
    suggestions: true,
    log: [] as any,
  }
  const updatedResponse = {
    ...defaultResponse,
  }
  if (data) {
    const { categorisation, pagination, results, suggestions = [] } = data
    if (
      getType(categorisation) === 'object' &&
      getType(pagination) === 'object' &&
      getType(results) === 'array' &&
      getType(suggestions) === 'array'
    ) {
      // Checking data integrity for: Facets
      if (categorisation.facets) {
        if (categorisation.facets.length > 0) {
          const tempInvalidFacets = [] as any
          categorisation.facets.map((facet) => {
            if (
              !facet.filters ||
              facet.filters.length === 0 ||
              !facet.name ||
              !facet.value
            ) {
              updatedResponse.categorisationFacets = false
              tempInvalidFacets.push(
                !facet.filters || facet.filters.length === 0 ? 'filters' : '',
                !facet.name ? 'name' : '',
                !facet.value ? 'value' : ''
              )
            }

            // Checking data integrity for: Facets/Filters
            facet.filters.map((filter) => {
              if (
                (!filter.count && filter.count !== 0) ||
                !filter.name ||
                (!filter.selected && typeof !filter.selected !== 'boolean') ||
                (!filter.unSelectAll &&
                  typeof !filter.unSelectAll !== 'boolean') ||
                !filter.value
              ) {
                updatedResponse.categorisationFacets = false
                tempInvalidFacets.push(
                  !filter.count && filter.count !== 0 ? 'filters/count' : '',
                  !filter.name ? 'filters/name' : '',
                  !filter.selected && typeof !filter.selected !== 'boolean'
                    ? 'filters/selected'
                    : '',
                  !filter.unSelectAll &&
                    typeof !filter.unSelectAll !== 'boolean'
                    ? 'filters/unSelectAll'
                    : '',
                  !filter.value ? 'filters/value' : ''
                )
              }
            })
          })
          if (tempInvalidFacets.length > 0) {
            updatedResponse.log.push({
              'Categorisation/facets data missing': [
                ...new Set(tempInvalidFacets),
              ].filter((entry) => entry !== ''),
            })
          }
        }
      } else {
        updatedResponse.categorisationFacets = false
        updatedResponse.log.push('Categorisation/facets missing')
      }

      // Checking data integrity for: groupFacets
      if (categorisation.groupFacets) {
        if (categorisation.groupFacets.length > 0) {
          const tempInvalidGroupFacets = [] as any
          categorisation.groupFacets.map((groupFacet) => {
            if (
              (!groupFacet.count && groupFacet.count !== 0) ||
              !groupFacet.name ||
              !groupFacet.value
            ) {
              updatedResponse.categorisationGroupFacets = false
              tempInvalidGroupFacets.push(
                !groupFacet.count && groupFacet.count !== 0 ? 'count' : '',
                !groupFacet.name ? 'name' : '',
                !groupFacet.value ? 'value' : ''
              )
            }
          })
          if (tempInvalidGroupFacets.length > 0) {
            updatedResponse.log.push({
              'Categorisation/groupFacets data missing': [
                ...new Set(tempInvalidGroupFacets),
              ].filter((entry) => entry !== ''),
            })
          }
        }
      } else {
        updatedResponse.categorisationGroupFacets = false
        updatedResponse.log.push('Categorisation/groupFacets missing')
      }

      // Checking data integrity for: sortItems
      if (categorisation.sortItems) {
        if (categorisation.sortItems.length > 0) {
          const tempInvalidSortItems = [] as any
          categorisation.sortItems.map((sortItem) => {
            if (!sortItem.name || !sortItem.sortType || !sortItem.sortValue) {
              updatedResponse.categorisationSortItems = false
              tempInvalidSortItems.push(
                !sortItem.name ? 'name' : '',
                !sortItem.sortType ? 'sortType' : '',
                !sortItem.sortValue ? 'sortValue' : ''
              )
            }
          })
          if (tempInvalidSortItems.length > 0) {
            updatedResponse.log.push({
              'Categorisation/sortItems data missing': [
                ...new Set(tempInvalidSortItems),
              ].filter((entry) => entry !== ''),
            })
          }
        }
      } else {
        updatedResponse.categorisationSortItems = false
        updatedResponse.log.push('Categorisation/sortItems missing')
      }

      // Checking data integrity for: Pagination
      if (
        !pagination.currentPage ||
        !pagination.pageSize ||
        !pagination.resultsPath ||
        (!pagination.totalItems && pagination.totalItems !== 0) ||
        (!pagination.totalPages && pagination.totalPages !== 0)
      ) {
        // As data are covered through code, we keep this entry as "valid" but log defects:
        updatedResponse.log.push({
          'Pagination data missing': [
            !pagination.currentPage ? 'currrentPage' : '',
            !pagination.pageSize ? 'pageSize' : '',
            !pagination.resultsPath ? 'resultsPath' : '',
            !pagination.totalItems && pagination.totalItems !== 0
              ? 'totalItems'
              : '',
            !pagination.totalPages && pagination.totalPages !== 0
              ? 'totalPages'
              : '',
          ].filter((entry) => entry !== ''),
        })
      }

      // Checking data integrity for: Results
      if (results.length > 0) {
        const tempInvalidResults = [] as any
        results.map((result) => {
          if (!result.contentType || !result.title || !result.url) {
            updatedResponse.results = false
            tempInvalidResults.push(
              !result.blurb && result.blurb !== '' ? 'blurb' : '',
              !result.contentType ? 'contentType' : '',
              !result.title ? 'title' : '',
              !result.url ? 'url' : ''
            )
          }
        })
        if (tempInvalidResults.length > 0) {
          updatedResponse.log.push({
            'Results data missing': [...new Set(tempInvalidResults)].filter(
              (entry) => entry !== ''
            ),
          })
        }
      }

      // Checking data integrity for: Suggestions
      if (suggestions.length > 0) {
        suggestions.map((suggestion) => {
          if (!suggestion.name || !suggestion.value) {
            updatedResponse.suggestions = false
            updatedResponse.log.push({
              'Suggestions data missing': [
                !suggestion.name ? 'name' : '',
                !suggestion.value ? 'value' : '',
              ].filter((entry) => entry !== ''),
            })
          }
        })
      }
    } else {
      return {
        categorisationFacets: false,
        categorisationGroupFacets: false,
        categorisationSortItems: false,
        pagination: false,
        results: false,
        suggestions: false,
        log: ['Minimum data structure not met.'],
      }
    }
  }
  return updatedResponse
}

export const validateAutoCompleteData = (data: IAutoCompleteResponse) => {
  const defaultResponse = {
    results: true,
    suggestions: true,
    log: [] as any,
  }
  const updatedResponse = {
    ...defaultResponse,
  }
  if (data) {
    const { results, suggestions } = data
    if (getType(results) === 'array' && getType(suggestions) === 'array') {
      // Checking data integrity for: Results
      if (results.length > 0) {
        const tempInvalidResults = [] as any
        results.map((result) => {
          if (!result.contentType || !result.title || !result.url) {
            updatedResponse.results = false
            tempInvalidResults.push(
              !result.contentType ? 'contentType' : '',
              !result.title ? 'title' : '',
              !result.url ? 'url' : ''
            )
          }
        })
        if (tempInvalidResults.length > 0) {
          updatedResponse.log.push({
            'Results data missing': [...new Set(tempInvalidResults)].filter(
              (entry) => entry !== ''
            ),
          })
        }
      }

      // Checking data integrity for: Suggestions
      if (suggestions.length > 0) {
        const tempInvalidSuggestions = [] as any
        suggestions.map((suggestion) => {
          if (typeof suggestion !== 'string') {
            updatedResponse.suggestions = false
            tempInvalidSuggestions.push('not a string')
          }
        })
        if (tempInvalidSuggestions.length > 0) {
          updatedResponse.log.push({
            'Suggestions data invalid': [
              ...new Set(tempInvalidSuggestions),
            ].filter((entry) => entry !== ''),
          })
        }
      }
    } else {
      return {
        results: false,
        suggestions: false,
        log: ['Minimum data structure not met.'],
      }
    }
  }
  return updatedResponse
}

export const useBackButton = (callBack: Function) => {
  const [isBack, setIsBack] = useState(false)
  const handleEvent = () => {
    callBack()
    setIsBack(true)
  }

  useEffect(() => {
    window.addEventListener('popstate', handleEvent)
    return () => window.removeEventListener('popstate', handleEvent)
  })

  return isBack
}

/**
 * This updates the browser history to allow for back and forth navigation between results/sorting...
 * @param resultsPath string
 */
export const updateHistory = (resultsPath: string) => {
  const currentURL = new URL(window.location.href)
  if (currentURL.search !== resultsPath) {
    currentURL.search = resultsPath
    history.pushState({}, '', currentURL.href)
  }
}

/**
 * This pushes up the parent container of the search results to avoid floating contents (filters, custom selector) to be overlapped by any subsequent content.
 * To be executed on load (useEffect(), [])
 * Use of this function breaks Jest as of 28.1.3. It needs mock stuff in the test file, please llok at CourseTable/CourseTable.test.tsx
 * BA 2024-09-25
 */
export const controlZIndexes = () => {
  const searchResults = document.querySelector('.searchResults')
  const ancestor = searchResults?.closest(
    'section.layoutContainer:has(+ section.layoutContainer), .block-container:has(+ .block-container)'
  ) as HTMLElement

  if (ancestor) {
    ancestor.style.zIndex = (
      (parseInt(ancestor.style.zIndex, 10) || 0) + 1
    ).toString()
  }
}
